/* Let's default this puppy out
-------------------------------------------------------------------------------*/

html,
body,
body div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
figure,
footer,
header,
menu,
nav,
section,
time,
mark,
audio,
video,
details,
summary {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent;
}

article,
aside,
figure,
footer,
header,
nav,
section,
details,
summary {
  display: block;
}

/* Handle box-sizing while better addressing child elements:
   https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */

*,
body,
*:before,
*:after {
  box-sizing: border-box;
}

/* Responsive images and other embedded objects
*/
img,
object,
embed {
  max-width: 100%;
}
img {
  display: block;
}

/*
   Note: keeping IMG here will cause problems if you're using foreground images as sprites.
	 In fact, it *will* cause problems with Google Maps' controls at small size.
	If this is the case for you, try uncommenting the following:

#map img {
		max-width: none;
}
*/

/* force a vertical scrollbar to prevent a jumpy page */
html {
  overflow-y: auto;
  overflow-x: hidden;
}

/* we use a lot of ULs that aren't bulleted.
	don't forget to restore the bullets within content. */
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help;
}

/* tables still need cellspacing="0" in the markup */
table {
  border-collapse: collapse;
  border-spacing: 0;
}
th {
  font-weight: bold;
  vertical-align: bottom;
}
td {
  font-weight: normal;
  vertical-align: top;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
}

pre {
  white-space: pre; /* CSS2 */
  white-space: pre-wrap; /* CSS 2.1 */
  white-space: pre-line; /* CSS 3 (and 2.1 as well, actually) */
  word-wrap: break-word; /* IE */
}

select,
input,
textarea {
  font: 99% sans-serif;
}

table {
  font-size: inherit;
  font: 100%;
}

small {
  font-size: 85%;
}

strong {
  font-weight: bold;
}

td,
td img {
  vertical-align: top;
}

/* Make sure sup and sub don't mess with your line-heights https://gist.github.com/413930 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}

/* standardize any monospaced elements */
pre,
code,
kbd,
samp {
  font-family: monospace, sans-serif;
}

/* hand cursor on clickable elements */
.clickable,
label,
input[type="button"],
input[type="submit"],
input[type="file"],
button {
  cursor: pointer;
}

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button,
input,
select,
textarea {
  margin: 0;
  padding: 0;
}

/* scale images in IE7 more attractively */
.ie7 img {
  -ms-interpolation-mode: bicubic;
}

/* prevent BG image flicker upon hover
   (commented out as usage is rare, and the filter syntax messes with some pre-processors)
.ie6 html {filter: expression(document.execCommand("BackgroundImageCache", false, true));}
*/

/* let's clear some floats */
.clearfix:before,
.clearfix:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden;
}
.clearfix:after {
  clear: both;
}
.clearfix {
  zoom: 1;
}

/* a tag text-decoration hiding */
a {
  text-decoration: none;
}

/* li tag style reseting */
li {
  list-style-type: none;
}

/* all input appearance reseting*/
input,
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
}
input[type="radio"] {
  vertical-align: text-bottom;
  -webkit-appearance: radio;
}
input[type="checkbox"] {
  vertical-align: bottom;
  -webkit-appearance: checkbox;
}

.ie7 input[type="checkbox"] {
  vertical-align: baseline;
}
.ie6 input {
  vertical-align: text-bottom;
}

/* number type inupt spinners hiding */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body {
  margin: 0;
  font-family: "Oswald", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.greed-container {
  position: relative;
  width: 100%;
  height: 100vh;
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.greed-container::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  background-image: url('./char_lamp.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  animation-name: grinder;
  animation-duration: 4s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  z-index: 20;
}

.beach--character{
  position: absolute;
  left: 10%;
  bottom: 0;
  height: 80vh;
  object-fit: contain;
  z-index: 30;
}

.greed-form {
  position: absolute;
  display: flex;
  align-items: flex-end;
  padding-inline-end: 22px;
  flex-direction: column;
  top: 50px;
  z-index: 20;
  min-width: fit-content;
  width: 100%;
  min-height: 320px;
  color: #fff;
  text-align: right;
  z-index: 55;
}

.greed-form h1 {
  font-size: 48px;
  text-shadow: 2px 2px #000;
}

.greed-form h2 {
  text-transform: uppercase;
  font-size: 40px;
  font-weight: bold;
  color: #fff;
}

.greed-form p {
  margin-block-start: auto;
  font-size: 24px;
}

.greed-form-counter {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #fff;
  margin-block-start: 30px;
  font-size: 40px;
  gap: 15px;
  color: #fff;
}

.greed-form-counter span {
  width: 20px;
}

.greed-form-counter .claim-btn {
  position: absolute;
  top: 0;
  height: 50px;
  right: 20px;
}

.greed-btn {
  align-self: center;
  font-size: 40px;
  line-height: 1;
  color: #fff;
}

.greed-btn[data-pos="minus"] {
  position: relative;
  top: -3px;
}

.greed-claimers {
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: space-evenly;
  margin: 32px 0;
}

.claim-btn {
  font-family: "Oswald";
  min-width: 150px;
  max-width: 200px;
  width: 100%;
  height: 60px;
  border: 1px solid #000;
  text-transform: uppercase;
  font-size: 30px;
  line-height: 1;
  text-align: center;
  color: #fff;
  background-color: #f552ce;
  transition: background-color 0.5s ease, color 0.5s ease;
}

.connect-wallet {
  font-family: "Oswald";
  width: 100%;
  height: 100px;
  border: 3px solid #f552ce;
  font-size: 60px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1;
  text-align: center;
  color: #fff;
  background-color: #f552ce;
  transition: background-color 0.5s ease, color 0.5s ease;
  margin: 26px auto;
}

.claim-btn:hover,
.connect-wallet:hover {
  background-color: #fff;
  color: #000;
}

a.claim-btn {
  display: block;
  max-width: 100%;
  margin: 8px auto 24px auto;
  background-color: #f552ce;
  color: #fff;
}

.greed-links {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  top: 20px;
  left: 50px;
  min-width: 100px;
  height: 50px;
  border-radius: 12px;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 0 6px;
  z-index: 50;
}

.greed-link-item {
  display: block;
  width: 35px;
  height: 35px;
}

.greed-link-item svg {
  width: 100%;
  height: 100%;
}

.counter {
  margin-block-start: 22px !important;
  font-size: 26px !important;
}

.greed-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
  margin-block-start: 40px;
}

.greed-wrapper > img {
  width: 300px;
  height: 300px;
  object-fit: cover;
}

@media screen and (max-width: 1200px) {
  .greed-container{
    background-position: -300px bottom;
  }
  .beach--character{
    left: -80px;
    height: 60vh;
  }
}

@media screen and (max-width: 992px) {
  .greed-form{
    top: 100px;
  }
  .greed-form h1{
    font-size: 40px;
  }
  .greed-container{
    background-position: center center;
  }
  .beach--character{
    left: 0;
    height: 50vh;
    object-fit: cover;
  }
  .greed-container::before{
    width: 100%;
  }
  .connect-wallet{
    font-size: 48px;
  }
}


@media screen and (max-width: 567px) {
  .connect-wallet{
    font-size: 30px;
  }
  .greed-form h1{
    font-size: 28px;
  }
  .greed-form h2{
    font-size: 32px;
  }
  .beach--character{
    width: 320px;
    height: 350px;
    object-position: left;
  }
  .claim-btn{
    min-width: fit-content;
    font-size: 24px;
  }
  .greed-form{
    padding: 0 16px;
  }
}


@keyframes grinder {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  30% {
    opacity: 0;
  }

  32% {
    opacity: 1;
  }

  48% {
    opacity: 0;
  }

  52% {
    opacity: 1;
  }

  53% {
    opacity: 0;
  }

  55% {
    opacity: 1;
  }

  59% {
    opacity: 0;
  }

  63% {
    opacity: 1;
  }

  67% {
    opacity: 0;
  }

  68% {
    opacity: 0;
  }

  72% {
    opacity: 1;
  }

  83% {
    opacity: 0;
  }

  85% {
    opacity: 1;
  }

  59% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
